import React, { useRef } from "react";
import { Link } from "react-router-dom";

export const Products = () => {

    const cement = [
        { image: "images/products/mpbirlachetak.jpg", title: "MP BIRLA CHETAK" },
        { image: "images/products/mpbirlasamrat.jpg", title: "MP BIRLA SAMRAT" },
        { image: "images/products/mpbirlaperfectplus.jpg", title: "MP BIRLA PERFECT PLUS CEMENT" },
        { image: "images/products/mpbirlarakshak.jpg", title: "MP BIRLA RAKSHAK" },
        { image: "images/products/jklakshmi.jpg", title: "JK LAKSHMI SUPREMO" },
        { image: "images/products/jklakshmipro.jpg", title: "JK LAKSHMI PRO +" },
    ]

    const whitecement = [
        // { image: "images/products/rakwhitecement.jpg", title: "RAK WHITE CEMENT" }
       
    ]

    const putty = [
        { image: "images/products/mpbirlaperfectwallputty.jpg", title: "MP BIRLA PERFECT PLUS WALL PUTTY" },
        { image: "images/products/jklakshmiwallputty.jpg", title: "JK LAKSHMI SMART WALL PUTTY" }
    ]

    const pop = [
        { image: "images/products/rcmgypsum.jpg", title: "RCM GYPSUM PLASTER" },
        { image: "images/products/pop.jpg", title: "JK LAKSHMI PLASTER OF PARIS" }
    ]

    const steel = [
        { image: "images/products/kamdhenusteeljpg.jpg", title: "KAMDHENU STEEL" },
        { image: "images/products/gksteel.jpg", title: "GK STEEL" },
        { image: "images/products/isisteel.jpg", title: "ISI RAIPUR STEEL" },
        { image: "images/products/steelangle.jpg", title: "ANGLE STEEL" },
        { image: "images/products/steelflat.jpg", title: "FLAT STEEL" },
        { image: "images/products/kay2-for-web.jpg", title: "KAY 2 XENOX STEEL" }
    ]

    const pipe = [
        { image: "images/products/mspipe.jpg", title: "MS PIPE" },
        { image: "images/products/squarepipe.jpg", title: "SQUARE PIPE" },
    ]

    return (
        <>
            <section className="page-title" style={{ backgroundImage: `url(images/background/8.jpg)` }}>
                <div className="auto-container">
                    <div className="inner-container clearfix">
                        <h1>Products</h1>
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Products</li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="product-title" >
                <div className="sec-title text-center">
                    <h2>Cement</h2>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
                    {cement.map((item, index) => {
                        return (
                            <div key={index} className="col">
                                <div className="card h-100">
                                    <img src={item.image} className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">{item.title}</h5>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
{/* 
            <div className="product-title" >
                <div className="sec-title text-center">
                    <h2>White Cement</h2>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
                    {whitecement.map((item, index) => {
                        return (
                            <div key={index} className="col">
                                <div className="card h-100">
                                    <img src={item.image} className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">{item.title}</h5>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div> */}

            <div className="product-title">
                <div className="sec-title text-center">
                    <h2>Putty</h2>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
                    {putty.map((item, index) => {
                        return (
                            <div key={index} className="col">
                                <div className="card h-100">
                                    <img src={item.image} className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">{item.title}</h5>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="product-title">
                <div className="sec-title text-center">
                    <h2>Plaster of Paris</h2>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
                    {pop.map((item, index) => {
                        return (
                            <div key={index} className="col">
                                <div className="card h-100">
                                    <img src={item.image} className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">{item.title}</h5>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="product-title">
                <div className="sec-title text-center">
                    <h2>Steel</h2>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
                    {steel.map((item, index) => {
                        return (
                            <div key={index} className="col">
                                <div className="card h-100">
                                    <img src={item.image} className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">{item.title}</h5>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="product-title">
                <div className="sec-title text-center">
                    <h2>Pipe</h2>
                </div>
                <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 g-4">
                    {pipe.map((item, index) => {
                        return (
                            <div key={index} className="col">
                                <div className="card h-100">
                                    <img src={item.image} className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">{item.title}</h5>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default Products;