import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

export const Header = () => {

    const [expanded, setExpanded] = useState(false);

    return (
        <div className="page-wrapper">
            <header className="main-header header-style-two">
                <div className="header-top">
                    <div className="auto-container">
                        <div className="inner-container clearfix">
                            <div className="top-left">
                                <ul className="clearfix">
                                    <li><i className="flaticon-cell-phone"></i> <a href="tel:+91-8889500561" title="Call us">+91-8889500561</a></li>
                                    <li><i className="fa fa-envelope-o"></i><a href="mailto:piyushtradersjbp@gmail.com" title="Write us">piyushtradersjbp@gmail.com</a></li>
                                </ul>
                            </div>
                            <div className="top-right">
                                <ul className="social-icon-one">
                                    <li><a href="https://www.facebook.com/piyushtrademart" target="_blank" title='Facebook'><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/piyush-trademart/" target="_blank" title='LinkedIn'><i className="fa fa-linkedin"></i></a></li>
                                    <li><a href="https://www.instagram.com/piyushtrademart/" target="_blank" title='Instagram'><i className="fa fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <Navbar collapseOnSelect expand="lg" expanded={expanded} variant="dark">
                    <Container>
                        <Navbar.Brand href="/">
                            <img
                                src="images/Piyush_trademart_logo.png"
                                className="d-inline-block align-top"
                                alt="logo"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"
                            onClick={() => setExpanded(expanded ? false : "expanded")}
                        />
                        <Navbar.Collapse id="responsive-navbar-nav ">
                            <Nav className="ms-auto ">
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <Link className="nav-link mobile-active" to="/" onClick={() => setExpanded(false)}>HOME</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link mobile-active" to="/about" onClick={() => setExpanded(false)}>ABOUT US</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link mobile-active" to="/products" onClick={() => setExpanded(false)}>PRODUCTS</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link mobile-active" to="/gallery" onClick={() => setExpanded(false)}>GALLERY</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link mobile-active" to="/careers" onClick={() => setExpanded(false)}>CAREERS</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link mobile-active" to="/contact" onClick={() => setExpanded(false)}>CONTACT</Link>
                                    </li>
                                </ul>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </div>
    );
}

export default Header;