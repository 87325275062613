import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

export const Home = () => {
    const [data, setData] = useState({});

    const handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        setData(values => ({ ...values, [name]: value }))
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post("http://piyushtrademart.com/piyushtraders.php", data)
            .then(function (response) {
                if (response.data.data === 'success') {
                    swal("Enquiry has been submitted successfully.", "We will contact you as soon as Possible.", "success");
                } else {
                    swal(response.data.message, "warning");
                }
            });
        setData({ fullname: '', email: '', phone: '', messages: '' });
    }

    return (
        <div>
            <div id="carouselExampleControls" className="carousel slide main-slider carousel-dark " data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img alt="banner1" className="rev-slidebg" data-bgfit="cover" data-bgparallax="10"
                            data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina=""
                            src="images/main-slider/banner6.jpg" />
                    </div>
                    <div className="carousel-item">
                        <img src="images/main-slider/banner3.jpg" className="d-block w-100" alt="banner2" />
                    </div>
                    <div className="carousel-item">
                        <img src="images/main-slider/banner1.jpg" className="d-block w-100" alt="banner3" />
                    </div>
                    <div className="carousel-item">
                        <img src="images/main-slider/banner5.jpg" className="d-block w-100" alt="banner4" />
                    </div>
                    <div className="carousel-item">
                        <img src="images/main-slider/banner4.jpg" className="d-block w-100" alt="banner5" />
                    </div>
                    <div className="carousel-item">
                        <img src="images/main-slider/banner2.jpeg" className="d-block w-100" alt="banner6" />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>


            <div className="product-title">
                <div className="sec-title text-center">
                    <h2>Products</h2>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
                    <div className="col">
                        <div className="card h-100">
                            <div id="carouselExampleControl" className="carousel carousel-dark slide" data-bs-ride="carousel" data-bs-interval="2000">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="images/products/mpbirlachetak.jpg" className="d-block w-100" alt="mpbirla" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="images/products/mpbirlasamrat.jpg" className="d-block w-100" alt="mpsamrat" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="images/products/mpbirlaperfectplus.jpg" className="d-block w-100" alt="perfectplus" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="images/products/mpbirlarakshak.jpg" className="d-block w-100" alt="rakshak" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="images/products/jklakshmi.jpg" className="d-block w-100" alt="jklakshmi" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="images/products/jklakshmipro.jpg" className="d-block w-100" alt="lakshmipro" />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControl" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControl" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">CEMENT</h5>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col">
                        <div className="card h-100">
                            <img src="images/products/rakwhitecement.jpg" className="d-block w-100" alt="rak" />
                            <div className="card-body">
                                <h5 className="card-title">WHITE CEMENT</h5>
                            </div>
                        </div>
                    </div> */}
                    <div className="col">
                        <div className="card h-100">
                            <div id="carouselExampleControl3" className="carousel carousel-dark slide" data-bs-ride="carousel" data-bs-interval="2000">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="images/products/mpbirlaperfectwallputty.jpg" className="d-block w-100" alt="puttywall" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="images/products/jklakshmiwallputty.jpg" className="d-block w-100" alt="wallputty" />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControl3" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControl3" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">PUTTY</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <div id="carouselExampleControl4" className="carousel carousel-dark slide" data-bs-ride="carousel" data-bs-interval="2000">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="images/products/rcmgypsum.jpg" className="d-block w-100" alt="rcmgypsum" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="images/products/pop.jpg" className="d-block w-100" alt="pop" />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControl4" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControl4" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">PLASTER OF PARIS</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <div id="carouselExampleControl5" className="carousel carousel-dark slide" data-bs-ride="carousel" data-bs-interval="2000">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="images/products/kamdhenusteeljpg.jpg" className="d-block w-100" alt="kamdhenu" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="images/products/gksteel.jpg" className="d-block w-100" alt="gk" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="images/products/isisteel.jpg" className="d-block w-100" alt="isi" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="images/products/steelangle.jpg" className="d-block w-100" alt="angle" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="images/products/steelflat.jpg" className="d-block w-100" alt="flat" />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControl5" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControl5" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">STEEL BRAND</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <div id="carouselExampleControl6" className="carousel carousel-dark slide" data-bs-ride="carousel" data-bs-interval="2000">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="images/products/mspipe.jpg" className="d-block w-100" alt="mspipe" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="images/products/squarepipe.jpg" className="d-block w-100" alt="squarepipe" />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControl6" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControl6" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">PIPE</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-boxs">
                    <Link to="/products" className="theme-btn btn-style-two">View All Products</Link>
                </div>
            </div>

            <section className="call-to-action" style={{ backgroundImage: `url(images/background/4.jpg)` }}>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="title-column col-md-9 col-sm-12 col-xs-12">
                            <div className="title">
                                <h2>Serving Our Clients for Over 25 Years</h2>
                            </div>
                            <div className="text">Piyush Traders has envisioned expanding its trading empire to every
                                district of India, with a vision of a seasoned business magnet Mr. Om Prakash Agrawal
                                along with next gen leadership of Mr. Himanshu Agrawal and Mr. Piyush Agrawal, it only
                                looks so very much achievable.
                            </div>
                        </div>

                        <div className="btn-column col-md-3 col-sm-12 col-xs-12">
                            <div className="btn-box">
                                <Link to="/contact" className="theme-btn btn-style-two">contact us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="feature-block col-md-4 col-sm-6 col-xs-12">
                            <div className="inner-box">
                                <div className="icon-box"><img src="images/logo/team.jpg" alt="icon1" /></div>
                                <h3>Team of 100+</h3>
                            </div>
                        </div>
                        <div className="feature-block col-md-4 col-sm-6 col-xs-12">
                            <div className="inner-box">
                                <div className="icon-box"><img src="images/logo/logistics.jpg" alt="icon2" /></div>
                                <h3>Logistics for Multicity Operations</h3>
                            </div>
                        </div>
                        <div className="feature-block col-md-4 col-sm-6 col-xs-12">
                            <div className="inner-box">
                                <div className="icon-box"><img src="images/logo/building.jpg" alt="icon3" /></div>
                                <h3>Huge Range of Products</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="call-back-section style-two" style={{ backgroundImage: `url(images/background/9.jpg)` }}>
                <div className="outer-box">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="content-column col-md-6 col-sm-12 col-xs-12">
                                <div className="inner-column">
                                    <h3>You are <br /> 10 minutes away from the Help you need</h3>
                                </div>
                            </div>

                            <div className="form-column col-md-6 col-sm-12 col-xs-12">
                                <div className="inner-column">
                                    <div className="appointment-form">
                                        <h3>Request A Call Back</h3>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    value={data.fullname || ''}
                                                    onChange={handleChange}
                                                    minLength="5"
                                                    type="text"
                                                    name="fullname"
                                                    placeholder="Full Name"
                                                    required />
                                            </div>

                                            <div className="form-group">
                                                <input
                                                    value={data.email || ''}
                                                    onChange={handleChange}
                                                    pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    required />
                                            </div>

                                            <div className="form-group">
                                                <input
                                                    value={data.phone || ''}
                                                    onChange={handleChange}
                                                    minLength="10"
                                                    maxLength="13"
                                                    type="tel"
                                                    name="phone"
                                                    placeholder="Phone"
                                                    required />
                                            </div>

                                            <div className="form-group">
                                                <textarea
                                                    name="messages"
                                                    placeholder="Your Message*"
                                                    value={data.messages || ''}
                                                    onChange={handleChange}
                                                    required>
                                                </textarea>
                                            </div>

                                            <div className="form-group">
                                                <button className="theme-btn btn-style-one" type="submit"
                                                    name="submit-form">Submit Now</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    );
}

export default Home;