import React, {useState} from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import swal from "sweetalert";

export const About = () => {
    const [data, setData] = useState({});

    const handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        setData(values => ({ ...values, [name]: value }))
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post("http://piyushtrademart.com/piyushtraders.php", data)
            .then(function (response) {
                if (response.data.data === 'success') {
                    swal("Enquiry has been submitted successfully.", "We will contact you as soon as Possible.", "success");
                } else {
                    swal(response.data.message, "warning");
                }
            });
        setData({ fullname: '', email: '', phone: '', messages: '' });
    }
    return (
        <div>
            <section className="page-title" style={{ backgroundImage: `url(images/background/8.jpg)` }}>
                <div className="auto-container">
                    <div className="inner-container clearfix">
                        <h1>About Us</h1>
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>About Us</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="about-us alternate">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="content-column col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <div className="sec-title">
                                    <span className="title">Journey of</span>
                                    <h2>PIYUSH TRADERS</h2>
                                </div>
                                <div className="text">
                                    The remarkable journey of PIYUSH TRADERS started in the 1998, when Mr. Om Prakash Agrawal,
                                    the founder of Piyush Traders ventured into trading business, he started with cement trading
                                    in 1998, with a very small investment of Two Hundred Thousand, he envisioned to establish
                                    biggest trading business of Cement in Madhya Pradesh, in coming years he went on to add
                                    many more products related to building and construction sector.
                                </div>
                                <div className="text">
                                    After ruling the trade of Building Materials for over 16 years, he ventured into manufacturing
                                    of bricks and paver blocks, in the year 2014. A manufacturing unit with the production capacity
                                    of 1 Lakh bricks per day, his elder son Mr. Himanshu Agrawal earnestly and efficiently manages
                                    the trading and manufacturing empire.
                                </div>
                                <div className="text">
                                    Piyush Traders further diversified the business in 2016 by venturing into Fuel Retailing and
                                    Transportation Services. The transportation business logistics include a fleet to transport
                                    Cement and Building Materials, which operates with a motto to deliver the goods in time.
                                </div>
                            </div>
                        </div>
                        <div className="video-column col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <figure className="image"><img src="images/gallery/aboutus_image.jpeg" alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="aboutuscontent">
                In 2019 a key decision was taken to expand business territory beyond the realms of Madhya Pradesh and hence the Cement
                Trading business was expanded to Maharashtra by setting up an office in Nagpur along with Cement we introduced Putty,
                POP, Construction chemical and White cement as well. <br />
                In 2019, Mr. Agrawal introduced his younger son Mr. Piyush Agrawal into the family business, who started taking care of
                cement, steel and transportation business. <br />
                In 2020, a significant vertical was added into the conglomerate by adding car dealership business and joining hands with
                India’s biggest car maker Maruti Suzuki by setting up dealership of Maruti Suzuki Arena in Jabalpur and in 2021 it was
                followed up by venturing into True Value the Second hand car platform of Maruti Suzuki. <br />
                With the employee strength of 100+ employees, warehousing capacity of more than 70,000 Sq and easy access storage capacity
                of 200000 Sq ft and with a fleet of 46 Trucks, Pickups, loaders and personnel mobility vehicles, we are big on logistics as well. <br />
                Piyush Traders has envisioned expanding its trading empire to every district of India, with a vision of a seasoned business magnet
                Mr. Om Prakash Agrawal along with next gen leadership of  Mr. Himanshu Agrawal and Mr. Piyush Agrawal, it only looks so very much achievable. <br />
                As we embark on this ambitious journey the erstwhile name Piyush Traders has made way for a brand new avatar and we are happy to introduce
                to you <span className="highlight">PIYUSH TRADEMART LLP</span>.
            </div>
            <section className="call-to-action" style={{ backgroundImage: `url(images/background/4.jpg)` }}>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="title-column col-md-9 col-sm-12 col-xs-12">
                            <div className="title">
                                <h2>Serving Our Clients for Over 25 Years</h2>
                            </div>
                            <div className="text">Piyush Traders has envisioned expanding its
                                trading empire to every district of India, with a vision of a seasoned
                                business magnet Mr. Om Prakash Agrawal along with next gen leadership of
                                Mr. Himanshu Agrawal and Mr. Piyush Agrawal, it only looks so very much achievable.
                            </div>
                        </div>

                        <div className="btn-column col-md-3 col-sm-12 col-xs-12">
                            <div className="btn-box">
                                <Link to="/contact" className="theme-btn btn-style-two">contact us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="features-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="feature-block col-md-4 col-sm-6 col-xs-12">
                            <div className="inner-box">
                                <div className="icon-box"><img src="images/logo/team.jpg" alt="icon4" /></div>
                                <h3>Team of 100+</h3>
                            </div>
                        </div>
                        <div className="feature-block col-md-4 col-sm-6 col-xs-12">
                            <div className="inner-box">
                                <div className="icon-box"><img src="images/logo/logistics.jpg" alt="icon5" /></div>
                                <h3>Logistics for Multicity Operations</h3>
                            </div>
                        </div>
                        <div className="feature-block col-md-4 col-sm-6 col-xs-12">
                            <div className="inner-box">
                                <div className="icon-box"><img src="images/logo/building.jpg" alt="icon6" /></div>
                                <h3>Huge Range of Products</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="fun-fact-section" style={{ backgroundImage: `url(images/background/9.jpg)` }}>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="title-column col-md-4 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <h3>We Have <br /> Great Achievements To Show !!</h3>
                            </div>
                        </div>

                        <div className="content-column col-md-8 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <div className="row clearfix">

                                    <div className="counter-column col-md-4 col-sm-6 col-xs-12">
                                        <div className="count-box">
                                            <span className="icon flaticon-users-1"></span>
                                            <h4 className="counter-title">Clients</h4>
                                            <span className="count-text" data-speed="3000" data-stop="750">900</span>+
                                        </div>
                                    </div>

                                    <div className="counter-column col-md-4 col-sm-6 col-xs-12">
                                        <div className="count-box">
                                            <span className="icon flaticon-engineer"></span>
                                            <h4 className="counter-title">Employees</h4>
                                            <span className="count-text" data-speed="3000" data-stop="2560">100</span>+
                                        </div>
                                    </div>
                                    <div className="counter-column col-md-4 col-sm-6 col-xs-12">
                                        <div className="count-box">
                                            <span className="icon flaticon-quality"></span>
                                            <h4 className="counter-title">Awards</h4>
                                            <span className="count-text" data-speed="3000" data-stop="150">30</span>+
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="call-back-section" style={{ backgroundImage: `url(images/background/9.jpg)` }}>
                <div className="outer-box">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="content-column col-md-6 col-sm-12 col-xs-12">
                                <div className="inner-column">
                                    <h3>You are <br /> 10 minutes away from the Help you need</h3>
                                </div>
                            </div>

                            <div className="form-column col-md-6 col-sm-12 col-xs-12">
                                <div className="inner-column">
                                    <div className="appointment-form">
                                        <h3>Request A Call Back</h3>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    value={data.fullname || ''}
                                                    onChange={handleChange}
                                                    minLength="5"
                                                    type="text"
                                                    name="fullname"
                                                    placeholder="Full Name"
                                                    required />
                                            </div>

                                            <div className="form-group">
                                                <input
                                                    value={data.email || ''}
                                                    onChange={handleChange}
                                                    pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    required />
                                            </div>

                                            <div className="form-group">
                                                <input
                                                    value={data.phone || ''}
                                                    onChange={handleChange}
                                                    minLength="10"
                                                    maxLength="13"
                                                    type="tel"
                                                    name="phone"
                                                    placeholder="Phone"
                                                    required />
                                            </div>

                                            <div className="form-group">
                                            <textarea
                                                name="messages"
                                                placeholder="Your Message*"
                                                value={data.messages || ''}
                                                onChange={handleChange}
                                                required>
                                            </textarea>
                                            </div>

                                            <div className="form-group">
                                                <button className="theme-btn btn-style-one" type="submit"
                                                    name="submit-form">Submit Now</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default About;