import React from 'react';
import { Link } from 'react-router-dom';

const Gallery = () => {
    const gallery1 = [
        { image: "images/gallery/image1.jpeg", id: 1 },
        { image: "images/gallery/image2.jpeg", id: 2 },
        { image: "images/gallery/image3.jpeg", id: 3 }
    ]

    const gallery2 = [
        { image: "images/gallery/image4.jpeg", id: 1 },
        { image: "images/gallery/image5.jpeg", id: 2 },
        { image: "images/gallery/image6.jpeg", id: 3 }
    ]

    const gallery3 = [
        { image: "images/gallery/image7.jpeg", id: 1 },
        { image: "images/gallery/image8.jpeg", id: 2 },
        { image: "images/gallery/image9.jpeg", id: 3 },
        { image: "images/gallery/image17.jpeg", id: 4 },
        { image: "images/gallery/image18.jpeg", id: 5 },
        { image: "images/gallery/image19.jpeg", id: 6 },
    ]

    const gallery4 = [
        { image: "images/gallery/image13.jpeg", id: 1 },
        { image: "images/gallery/image14.jpeg", id: 2 },
        { image: "images/gallery/image15.jpeg", id: 3 },
        { image: "images/gallery/image16.jpeg", id: 4 },
    ]

    const gallery5 = [
        { image: "images/gallery/image10.jpeg", id: 1 },
        { image: "images/gallery/image11.jpeg", id: 2 }
    ]


    return (
        <>
            <section className="page-title" style={{ backgroundImage: `url(images/background/8.jpg)` }}>
                <div className="auto-container">
                    <div className="inner-container clearfix">
                        <h1>Gallery</h1>
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Gallery</li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="product-title">
                <div className="sec-title text-center">
                    <h2>Contractor and Retail Meet</h2>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
                    {gallery1.map((item) => {
                        return (
                            <div key={item.id} className="col">
                                <div className="card h-100">
                                    <img src={item.image} className="card-img-top" alt="..." />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="product-title">
                <div className="sec-title text-center">
                    <h2>Entrepreneur Award</h2>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
                    {gallery2.map((item) => {
                        return (
                            <div key={item.id} className="col">
                                <div className="card h-100">
                                    <img src={item.image} className="card-img-top" alt="..." />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="product-title">
                <div className="sec-title text-center">
                    <h2>Top Management Birthday celebration</h2>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
                    {gallery3.map((item) => {
                        return (
                            <div key={item.id} className="col">
                                <div className="card h-100">
                                    <img src={item.image} className="card-img-top" alt="..." />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="product-title">
                <div className="sec-title text-center">
                    <h2>Women's Day Celebration</h2>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
                    {gallery4.map((item) => {
                        return (
                            <div key={item.id} className="col">
                                <div className="card h-100">
                                    <img src={item.image} className="card-img-top" alt="..." />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="product-title">
                <div className="sec-title text-center">
                    <h2>Technical Bike Launch</h2>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
                    {gallery5.map((item) => {
                        return (
                            <div key={item.id} className="col">
                                <div className="card h-100">
                                    <img src={item.image} className="card-img-top" alt="..." />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default Gallery;