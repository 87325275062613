import React from 'react';
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <div>
            <footer className="main-footer">
                <div className="auto-container">
                    <div className="widgets-section">
                        <div className="row clearfix">
                            <div className="big-column col-md-9 col-sm-12 col-xs-12">
                                <div className="row clearfix">
                                    <div className="footer-column col-md-9 col-sm-8 col-xs-12">
                                        <div className="footer-widget about-widget">
                                            <div className="footer-logo">
                                                <figure>
                                                    <Link to="/"><img src="images/Piyush_trademart_logo.png" alt="logo_website" /></Link>
                                                </figure>
                                            </div>
                                            <div className="widget-content">
                                                <div className="text">PIYUSH TRADERS started in the 1998, when Mr. Om Prakash Agrawal,
                                                    the founder of Piyush Traders ventured into trading business, he started with cement trading in 1998,
                                                    with a very small investment of Two Hundred Thousand, he envisioned to establish biggest trading
                                                    business of Cement in Madhya Pradesh, in coming years he went on to add many more products related
                                                    to building and construction sector.
                                                </div>

                                                <div className="btn-box">
                                                    <Link to="/about" className="theme-btn btn-style-two">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="footer-column col-md-3 col-sm-4 col-xs-12">
                                        <div className="footer-widget links-widget">
                                            <h2 className="widget-title">our products</h2>
                                            <div className="widget-content">
                                                <ul className="list">
                                                    <li>Cement</li>
                                                    <li>White Cement</li>
                                                    <li>Putty</li>
                                                    <li>Plaster of Paris</li>
                                                    <li>Steel</li>
                                                    <li>Pipe</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="big-column col-md-3 col-sm-12 col-xs-12">
                                <div className="row clearfix">
                                    <div className="footer-column col-md-12 col-sm-12 col-xs-12">
                                        <div className="footer-widget contact-widget">
                                            <h2 className="widget-title">Contact us</h2>
                                            <div className="widget-content">
                                                <ul className="contact-list">
                                                    <li><span className="flaticon-cell-phone"></span>
                                                        <a href="tel:+91-8889500561" title="Call us">+91-8889500561</a>
                                                    </li>
                                                    <li><span className="fa fa-envelope"></span>
                                                        <a href="mailto:info.piyushtradersjbp@gmail.com" title="Write us" style={{fontSize: "small"}}>piyushtradersjbp@gmail.com</a>
                                                    </li>
                                                    <li>
                                                        <span className="fa fa-map-marker"></span>  Damoh Rd, Shiv Nagar, Jabalpur
                                                    </li>
                                                </ul>
                                                <div className='sociallinks'>
                                                    <h2 className="widget-title">Social Links</h2>
                                                    <ul className="social-icon-two">
                                                        <li><a href="https://www.facebook.com/piyushtrademart" target="_blank" title='Facebook'><i className="fa fa-facebook"></i></a></li>
                                                        <li><a href="https://www.linkedin.com/company/piyush-trademart/" target="_blank" title='LinkedIn'><i className="fa fa-linkedin"></i></a></li>
                                                        <li><a href="https://www.instagram.com/piyushtrademart/" target="_blank" title='Instagram'><i className="fa fa-instagram"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="copyright-text">PIYUSH TRADEMART © COPYRIGHT 2022, ALL RIGHTS RESERVED, DESIGNED & DEVELOPED BY :
                            <a href="http://www.cics.co.in" rel="noreferrer" target="_blank" >
                                <span className="lnk">&nbsp;CICS</span>
                            </a>
                        </div>
                    </div>
                </div>
            </footer >
        </div >
    );
}

export default Footer;