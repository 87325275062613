import React, { useState } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import swal from "sweetalert";

export const Contact = () => {
    const [data, setData] = useState({});

    const handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        setData(values => ({ ...values, [name]: value }))
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post("http://piyushtrademart.com/piyushtraders.php", data)
            .then(function (response) {
                if (response.data.data === 'success') {
                    swal("Enquiry has been submitted successfully.", "We will contact you as soon as Possible.", "success");
                } else {
                    swal(response.data.message, "warning");
                }
            });
        setData({ fullname: '', email: '', phone: '', messages: '', subject: '' });
    }

    return (
        <div>
            <section className="page-title" style={{ backgroundImage: `url(images/background/10.jpg)` }}>
                <div className="auto-container">
                    <div className="inner-container clearfix">
                        <h1>Contact</h1>
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Contact</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="contact-page-section">
                <div className="auto-container">
                    <div className="heading-box clearfix">
                        <div className="sec-title">
                            <h2>Contact Info</h2>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="form-column col-lg-9 col-md-12 col-sm-12 col-xs-12">
                            <div className="contact-form-two">
                                <form onSubmit={handleSubmit}>
                                    <div className="row clearfix">
                                        <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                            <input
                                                minLength="5"
                                                type="text"
                                                name="fullname"
                                                placeholder="Full Name*"
                                                required
                                                value={data.fullname || ''}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                            <input
                                                pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                                                type="email"
                                                name="email"
                                                placeholder="Email*"
                                                required
                                                value={data.email || ''}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                            <input
                                                type="text"
                                                name="subject"
                                                placeholder="Subject"
                                                value={data.subject || ''}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                            <input
                                                minLength="10" maxLength="13"
                                                type="tel"
                                                name="phone"
                                                placeholder="Phone*"
                                                required
                                                value={data.phone || ''}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <textarea
                                                name="messages"
                                                placeholder="Your Message*"
                                                value={data.messages || ''}
                                                onChange={handleChange}
                                                required>
                                            </textarea>
                                        </div>

                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <button className="theme-btn btn-style-two" type="submit" name="Submit">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card col-lg-3 col-md-12 col-sm-12 col-xs-12" style={{ height: "fit-content", paddingBottom: "30px" }}>
                            <div className="contact-column ">
                                <div className="inner-column">
                                    <ul className="contact-info">
                                        <li>
                                            <span className="icon flaticon-home"></span>
                                            <strong>Damoh Rd, Shiv Nagar,</strong>
                                            <p> Jabalpur, Madhya Pradesh, India</p>
                                        </li>

                                        <li>
                                            <span className="icon flaticon-mail"></span>
                                            <strong>Send your mail at</strong>
                                            <p><a href="mailto:info.piyushtrademart@gmail.com" title="Write us">info.piyushtrademart@gmail.com</a></p>
                                        </li>
                                        <li>
                                            <span className="icon flaticon-phone-1"></span>
                                            <strong>Have Any Question</strong>
                                            <p><a href="tel:+91-9329931635" title="Call us">+91-9329931635</a></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="row">
                <h1 className="text-center text-uppercase">How to reach us</h1>
                <div className="col-md-12" style={{ padding: '30px' }} >
                    <iframe title="Google Map" 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.493677546479!2d79.92008081448267!3d23.188672715919715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3981af8b2e4b5857%3A0x942ca6dc54845e00!2sPiyush%20Traders!5e0!3m2!1sen!2sin!4v1652443818696!5m2!1sen!2sin"
                        style={{ width: '100%', height: '350px', border: '0' }} allowFullScreen="">
                    </iframe>
                </div>
            </div>
        </div>
    );
}

export default Contact;