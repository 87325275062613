import React from 'react';
import { Link } from 'react-router-dom';

const Career = () => {
    return (
        <>
            <section className="page-title" style={{ backgroundImage: `url(images/background/11.jpg)` }}>
                <div className="auto-container">
                    <div className="inner-container clearfix">
                        <h1>Careers</h1>
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Careers</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="about-us alternate">
                <div className="row career-container">
                    <div className="content-column col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="inner-column">
                            <div className="sec-title">
                                <h1>What Drives Us</h1>
                            </div>
                            <div className="text">
                                Our company culture is founded on the belief that we are the trustee
                                of our human resources and encourages to develop them to introspect
                                deeply and learn to act not on directives but on inspiration from
                                within, thus aiming to provide a strong motivation to our employees
                                to become psychological co-owners of the organization and to succeed
                                with DETERMINATION & COMMITMENT.
                            </div>
                        </div>
                    </div>
                    <div className="career-column col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="inner-column">
                            <figure className="images"><img src="images/logo/career.png" alt="careers" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-us alternate">
                <div className="row career-container">
                    <div className="career-column col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="inner-column">
                            <figure className="images"><img src="images/logo/opportunity.png" alt="careers" />
                            </figure>
                        </div>
                    </div>
                    <div className="content-column col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="inner-column">
                            <div className="sec-title">
                                <h1>Opportunities  <br /> at Piyush Trademart</h1>
                            </div>
                            <div className="text">
                                Write us, and build your future with the company. <br />
                                <strong>Send your Resume at : </strong>
                                <p><a href="mailto:hr.piyushtradersjbp@gmail.com" title="Write us">hr.piyushtradersjbp@gmail.com</a></p>
                              
                            </div>
                            <table className='table table-bordered table-condensed table-responsive'>
                                    <thead>
                                        <tr>
                                            <th>Position</th>
                                            <th style={{width:100}}>   Location   </th>
                                            <th>Required Skillset/Experience</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Marketing Executive</td>
                                                <td>Betul <br/> Chhindwara <br/> Damoh <br/> Mandla<br/> Narsingpur<br/>
                                                 Katni<br/>Satna</td>
                                                <td>5+ years Experience in Sales & Marketing. Experience in Construction, Building Material, Trading line or related field will be preferred. </td>
                                            </tr>
                                        </tbody>
                                    
                                </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Career;